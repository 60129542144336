import * as React from "react";
import TemplatePage1 from "@components/TemplatePage/TemplatePage";
import styled from "styled-components";
import AuthorList from "@components/AuthorList/AuthorList";
import AuthorPublications from "@components/AuthorPublications/AuthorPublications";


const Wrapper = styled.div`
`;


const IndexPage = () => {
  return (
    <TemplatePage1
      title={"Patricia J. Garcia"}
      content={(
        <Wrapper >
          <AuthorPublications authorData={{"given": "Patricia J","family": "Garcia"}}/>
        </Wrapper >
      )}
      extra={
        <AuthorList/>
      }
    />
  )
};

export default IndexPage;
